
import {defineComponent, ref} from "vue";
import {IonButton, IonCol, IonItem, IonLabel} from "@ionic/vue";
import {Order} from "@/domain/model/order/order.model";
import router from "@/app/router";
import {formatDate} from "@/domain/helper/date.helper";


export default defineComponent({
  name: "MaterialDetailMaterialOrdersComponent",
  components: {
    IonCol,
    IonItem,
    IonLabel,
    IonButton,
  },
  props: {
    order: null,
  },
  setup(prop: any) {
    const orderProp = ref<Order>(prop.order);
    const endDate = formatDate(orderProp.value.deliveryDate, "DD-MM-YYYY");

    const goOrderDetails = (id: string) => {
      router.push({
        name: 'detailsProduct',
        params: {id: id}
      });
    }

    return {
      endDate,
      orderProp,
      goOrderDetails,
    };
  }
})
