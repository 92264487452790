
import {defineComponent, ref, watch} from "vue";
import {Stock, StockRecord, StockTimeline} from "@/domain/model/stock/stock.model";
import {useStore} from "vuex";
import {
  alertController,
  IonButton, IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon, IonInput, IonItem,
  IonLabel,
  IonPage,
  IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, loadingController,
  modalController
} from "@ionic/vue";
import {closeOutline} from "ionicons/icons";
import {useStockApi} from "@/domain/service/api/stock.api";
import _ from "lodash";
import {formatDate} from "@/domain/helper/date.helper";
import {useStock} from "@/domain/service/model/stock.service";


export default defineComponent({
  name: "StockUpdatePage",
  components: {
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonHeader,
    IonButton,
    IonIcon,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonItem,
  },
  props: {
    material: null,
  },
  setup(prop: any) {
    const store = useStore();
    const factorStock = ref<Stock>(store.getters.lastStockByRef(prop.material?.ref));
    const movementAmount = ref<number>(0);
    const direction = ref<string>('Eliminate');
    const concept = ref<string>('Ajuste de Stock');
    const isSubmitting = ref<boolean>(false);

    const onNotNegative = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Valor invalido',
            message: 'Escriba la cantidad a modificar en positivo, e indique si quiere añadir o restar en el selector correspondiente',
            buttons: [
              {
                text: 'Aceptar',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel:');
                },
              },
            ],
          });

      await alert.present();

      const {role} = await alert.onDidDismiss();
    }
    const onNotEmpty = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Valor invalido',
            message: 'Escriba valores correctos en todos los campos',
            buttons: [
              {
                text: 'Aceptar',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel:');
                },
              },
            ],
          });

      await alert.present();

      const {role} = await alert.onDidDismiss();
    }
    const onExit = async () => {
      return modalController.dismiss();
    }

    const onSaveStock = async () => {
      if (movementAmount.value.toString().includes('-')) {
        await onNotNegative();
        return;
      }
      if (!movementAmount.value || !concept.value || !direction.value) {
        await onNotEmpty();
        return;
      }

      if (isSubmitting.value)
        return;

      isSubmitting.value = true;

      const loading = await loadingController
          .create({
            message: 'Modificando Stock',
            duration: 10000,
          });

      await loading.present();

      const newStockRecord: StockRecord = useStock().emptyStockRecord();
      if (direction.value == 'Eliminate')
        newStockRecord.amount = (-1) * movementAmount.value;
      else
        newStockRecord.amount = (1) * movementAmount.value;

      newStockRecord.concept = concept.value;

      await useStockApi().updateStockTimeline(newStockRecord, factorStock.value.id);

      await loading.dismiss();
      isSubmitting.value = false;
    }

    return {
      onExit,
      closeOutline,
      movementAmount,
      direction,
      concept,
      onSaveStock,
    };
  }
})
