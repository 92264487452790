
import {computed, defineComponent, ref, watch} from "vue";
import {IonButton, IonCol, IonInput, IonItem, IonLabel, loadingController} from "@ionic/vue";
import {Material} from "@/domain/model/material/material.model";
import {useMaterialApi} from "@/domain/service/api/material.api";
import _ from "lodash";

export default defineComponent({
  name: "MaterialDetailItemDataComponent",
  components: {
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  },
  props: {
    material: null,
  },
  setup(prop: any) {
    const {update} = useMaterialApi();
    const materialProp = ref<Material>(prop.material);
    const materialName = ref<string>(materialProp.value.name);
    const isChangeDone = ref<boolean>(false);

    const sendChanges = async () => {
      const loading = await loadingController
          .create({
            message: 'Guardando cambios',
            duration: 10000,
          });

      await loading.present();
      const newMaterialUpdated: Material = _.cloneDeep(materialProp.value);
      newMaterialUpdated.name = materialName.value;
      isChangeDone.value = false;
      await update(newMaterialUpdated);
      await loading.dismiss();

    };
    watch(materialName, () => {
      isChangeDone.value = true;
    })

    return {
      materialProp,
      materialName,
      isChangeDone,
      sendChanges,
    }

  }
})
