

import {computed, defineComponent, onMounted, ref} from "vue";
import {
  IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonCol,
  IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel,
  IonPage, IonRow, IonTitle, IonToolbar, modalController, onIonViewWillEnter,
} from "@ionic/vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import {Order} from "@/domain/model/order/order.model";
import MaterialDetailMaterialOrdersComponent
  from "@/app/view/material/detail/MaterialDetailMaterialOrdersComponent.vue";
import MaterialDetailItemDataComponent from "@/app/view/material/detail/MaterialDetailItemDataComponent.vue";
import router from "@/app/router";
import {useStock} from "@/domain/service/model/stock.service";
import {Stock} from "@/domain/model/stock/stock.model";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useStockApi} from "@/domain/service/api/stock.api";
import _ from "lodash";
import OrderUpdatePage from "@/app/view/order/update/OrderUpdatePage.vue";
import StockUpdatePage from "@/app/view/stock/update/StockUpdatePage.vue";

export default defineComponent({
  name: "MaterialDetailPage",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonBackButton,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonButton,
    IonBadge,
    MaterialDetailMaterialOrdersComponent,
    MaterialDetailItemDataComponent,

  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const id = ref(route.params.id);
    const {calculateBlockStock} = useStock();
    const material = computed<Material>(() => store.getters.materialById(id.value));
    // const ordersOfMaterial = computed<Order[]>(() => store.getters.activeOrdersByMaterial(material.value));
    const ordersOfMaterial = computed<Order[]>(() => {
      const orders: Order[] = _.cloneDeep(store.getters.activeOrdersByMaterial(material.value));
      return _.sortBy(orders, [(order: Order) => order.material.ref], ['asc'])
    });


    const factoryStock = computed<Stock>(() => {
      return store.getters.lastStockByRef(material.value?.ref)
    });
    const blockStock = computed<number>(() => calculateBlockStock(store.getters.allActiveOrders, material.value?.ref))
    const availableStock = computed<number>(() => factoryStock.value?.amount - blockStock.value);
    const isThereAvailability = computed<boolean>(() => availableStock.value >= 0)

    const onUpdateStock = async () => {
      const modal = await modalController
          .create({
            component: StockUpdatePage,
            componentProps: {material: material.value},
            backdropDismiss: false,
          });
      return modal.present();
    }

    const goMaterialRecord = () => {
      router.push({
        name: 'StockItemRecordPage',
        params: {id: id.value}
      });
    };

    onIonViewWillEnter(async () => {
      if (id.value === route.params.id)
        return;

      id.value = route.params.id;
      await useMaterialApi().fetchAll();
      await useStockApi().fetchAll();
    });

    return {
      onUpdateStock,
      isThereAvailability,
      factoryStock,
      blockStock,
      availableStock,
      material,
      ordersOfMaterial,
      goMaterialRecord,
    };

  }
})
